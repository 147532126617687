@font-face {
  font-family: "Poppins";
  src: local("Poppins Medium"), local("Poppins-Medium"),
    url("../fonts/Poppins-Medium.woff2") format("woff2"),
    url("../fonts/Poppins-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Bold Italic"), local("Poppins-BoldItalic"),
    url("../fonts/Poppins-BoldItalic.woff2") format("woff2"),
    url("../fonts/Poppins-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Regular"), local("Poppins-Regular"),
    url("../fonts/Poppins-Regular.woff2") format("woff2"),
    url("../fonts/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Black Italic"), local("Poppins-BlackItalic"),
    url("../fonts/Poppins-BlackItalic.woff2") format("woff2"),
    url("../fonts/Poppins-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins SemiBold Italic"), local("Poppins-SemiBoldItalic"),
    url("../fonts/Poppins-SemiBoldItalic.woff2") format("woff2"),
    url("../fonts/Poppins-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Black"), local("Poppins-Black"),
    url("../fonts/Poppins-Black.woff2") format("woff2"),
    url("../fonts/Poppins-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins SemiBold"), local("Poppins-SemiBold"),
    url("../fonts/Poppins-SemiBold.woff2") format("woff2"),
    url("../fonts/Poppins-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Light Italic"), local("Poppins-LightItalic"),
    url("../fonts/Poppins-LightItalic.woff2") format("woff2"),
    url("../fonts/Poppins-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Thin"), local("Poppins-Thin"),
    url("../fonts/Poppins-Thin.woff2") format("woff2"),
    url("../fonts/Poppins-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Medium Italic"), local("Poppins-MediumItalic"),
    url("../fonts/Poppins-MediumItalic.woff2") format("woff2"),
    url("../fonts/Poppins-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins ExtraBold"), local("Poppins-ExtraBold"),
    url("../fonts/Poppins-ExtraBold.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Bold"), local("Poppins-Bold"),
    url("../fonts/Poppins-Bold.woff2") format("woff2"),
    url("../fonts/Poppins-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Light"), local("Poppins-Light"),
    url("../fonts/Poppins-Light.woff2") format("woff2"),
    url("../fonts/Poppins-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Thin Italic"), local("Poppins-ThinItalic"),
    url("../fonts/Poppins-ThinItalic.woff2") format("woff2"),
    url("../fonts/Poppins-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins Italic"), local("Poppins-Italic"),
    url("../fonts/Poppins-Italic.woff2") format("woff2"),
    url("../fonts/Poppins-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins ExtraBold Italic"), local("Poppins-ExtraBoldItalic"),
    url("../fonts/Poppins-ExtraBoldItalic.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins ExtraLight Italic"), local("Poppins-ExtraLightItalic"),
    url("../fonts/Poppins-ExtraLightItalic.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: local("Poppins ExtraLight"), local("Poppins-ExtraLight"),
    url("../fonts/Poppins-ExtraLight.woff2") format("woff2"),
    url("../fonts/Poppins-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

img,
picture,
svg,
video {
  display: block;
  max-width: 100%;
}

.img__logo__container {
  width: 425px;
}

.img__logo {
  width: 100%;
  height: 100%;
  max-width: 128px;
  max-height: 32px;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
}

html {
  scroll-behavior: smooth;
}

body {
  min-height: 100vh;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  line-height: 1.5em;
  text-rendering: optimizeLegibility !important;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
  overflow-x: hidden;
}

.overflow {
  overflow: hidden !important;
}

/*
 *   Cookie Banner
 */

.cookie__backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 150;
  backdrop-filter: blur(20px);
  background-color: rgba(0, 0, 0, 0.1);
}

.cookie__wrapper {
  position: fixed;
  display: flex;
  align-items: center;
  gap: 20px;
  width: calc(100% - 40px);
  max-width: 768px;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 20px;
  border-radius: 1rem;
  background-color: rgba(15, 15, 15, 0.85);
  backdrop-filter: blur(20px);
  z-index: 160;
}

.cookie__media__query {
  display: flex;
  align-items: center;
  gap: 20px;
}

.cookie__icon__wrapper {
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  border-radius: 50%;
  background-color: #0f0f0f;
}

.cookie__icon__wrapper svg {
  width: 16px;
  height: 16px;
}

.cookie__icon__wrapper path {
  fill: #fff;
}

.cookie__message,
.cookie__message > a {
  color: #fff;
}

.cookie__message > a {
  text-decoration: underline;
}

.cookie__button {
  outline: none;
  background-color: transparent;
  font-size: 14px;
  padding: 10px 20px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 24px;
  transition: color 300ms ease, background-color 300ms ease;
  cursor: pointer;
}

.cookie__button:hover {
  color: #000;
  background-color: #fff;
}

/*
 * Loader
 */
.block {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.loader__text__wrapper {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #000;
  z-index: 9998;
}

.loader__text {
  font-family: "Poppins", normal;
  font-size: 32px;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  color: #fff;
}

.loader__text .letter {
  display: inline-block;
  line-height: 1em;
}

.revealer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #f1a438;
  z-index: 9999;
}

.pre__loader {
  position: absolute;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 10000;
}

.loader__wrapper {
  position: relative;
  width: 10px;
  height: 140px;
  background: rgb(240, 240, 240);
  border-radius: 4px;
  overflow: hidden;
  transform: rotate(90deg);
}

.loader {
  position: absolute;
  top: 100%;
  width: 100%;
  height: 100%;
  background: rgb(255, 136, 0);
  background: linear-gradient(
    100deg,
    rgb(255, 208, 0) 0%,
    rgba(255, 136, 0, 1) 100%
  );
}

.cursor {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  transform: scale(0);
  transform-origin: center;
  color: #fff;
  background-color: #000;
  transition: color 0.4s cubic-bezier(0.195, 1, 0.225, 1),
    background-color 0.4s cubic-bezier(0.195, 1, 0.225, 1),
    opacity 1s cubic-bezier(0.195, 1, 0.225, 1);
  pointer-events: none !important;
  border-radius: 50%;
  z-index: 1000000;
  opacity: 1;
}

.cursor__content {
  -webkit-transition: opacity 0.5s linear,
    -webkit-transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  transition: opacity 0.5s linear,
    -webkit-transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s linear;
  transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1), opacity 0.5s linear,
    -webkit-transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
  -webkit-transition-delay: 0.15s;
  transition-delay: 0.15s;
  pointer-events: none !important;
}

.cursor__text {
  font-size: calc(1rem + 0.125 * ((100vw - 48rem) / 42));
  letter-spacing: 0.01em;
  line-height: 150%;
  pointer-events: none !important;
}

.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  transform: translateY(0);
  background-color: transparent;
  -webkit-backdrop-filter: blur(0);
  backdrop-filter: blur(20px);
  box-shadow: 0 0 100px 0 rgba(230, 235, 235, 0.02), 0 0 100px 100px rgba(230, 235, 235, 0.01);
  transition: transform 0.45s cubic-bezier(0.77, 0, 0.175, 1), background-color 0.25s ease-out, box-shadow 0.25s ease-in, top 0.45s cubic-bezier(0.77, 0, 0.175, 1), -webkit-backdrop-filter 0.25s ease-out;
  transition: transform 0.45s cubic-bezier(0.77, 0, 0.175, 1), background-color 0.25s ease-out, box-shadow 0.25s ease-in, backdrop-filter 0.25s ease-out, top 0.45s cubic-bezier(0.77, 0, 0.175, 1);
  transition: transform 0.45s cubic-bezier(0.77, 0, 0.175, 1), background-color 0.25s ease-out, box-shadow 0.25s ease-in, backdrop-filter 0.25s ease-out, top 0.45s cubic-bezier(0.77, 0, 0.175, 1), -webkit-backdrop-filter 0.25s ease-out;
  will-change: transform;
  z-index: 1000;
  padding: 0;
}

.navbar.threshhold__scroll {
  backdrop-filter: blur(15px);
  background-color: hsla(0, 0%, 100%, 0.8);
  box-shadow: 0 0 100px 0 rgb(25 20 20 / 2%), 0 0 100px 100px rgb(25 20 20 / 1%);
}

.navbar__main__menu {
  width: 100%;
  display: flex;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  color: #000;
  padding: 5px 20px;
}

.navbar__main__menu a {
  display: flex;
  height: 100%;
  align-items: center;
}

.navbar__submenu {
  display: flex;
  height: 60px;
  padding: 0 30px;
}

.navbar__menu__list {
  display: flex;
  width: 100%;
  gap: 30px;
  height: 100%;
}

.navbar__menu__list_width {
  width: 600px;
  justify-content: center;
}

.navbar__menu__item {
  font-size: 1.125rem;
  font-weight: 400;
  color: #000;
  transition: color 300ms ease;
}

.navbar__main__menu > .navbar__menu__item,
.navbar__menu__list > .navbar__menu__item {
  position: relative;
  display: none;
  width: fit-content;
  color: #191414 !important;
  background-color: #fff;
  padding: 19px 30px;
  border-radius: 100px;
  margin-top: 10px;
}

.navbar__main__menu > .navbar__menu__item {
  display: block;
  height: fit-content;
  color: #fff !important;
  background-color: #191414;
  padding: 10px 20px;
  margin-top: 0;
  border: 1px solid transparent;
  transition: background-color 300ms ease, color 300ms ease,
    border-color 300ms ease;
}

.navbar__main__menu > .navbar__menu__item:hover {
  color: #191414 !important;
  background-color: transparent;
  border-color: #191414;
}

.navbar__menu__list > .navbar__menu__item span {
  background-color: #ffc864;
  border-radius: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -12px;
  right: -5px;
  padding: 6px 10px;
  font-size: calc(0.75rem + 0.125 * ((100vw - 48rem) / 42));
  letter-spacing: 0.01em;
  line-height: 1.15;
}

.dark .navbar__menu__item,
.threshhold__scroll .navbar__menu__item {
  color: #000;
}

.navbar__menu__item:hover {
  color: #4e4e4e;
}

.navbar__trigger {
  display: none;
  cursor: pointer;
  appearance: none;
  outline: none;
  border: 0;
  background: none;
  padding: 0;
  transform: translate3d(0, 0, 0);
}

.hamburger__line {
  width: 25px;
  height: 1px;
  background-color: #191414;
  margin: 4px 0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  display: block;
}

.hamburger__line:nth-of-type(2) {
  background: none;
}

.open .hamburger__line:first-child {
  background: #fff;
  transform: rotate(-45deg) translate(-3px, 4px);
}

.open .hamburger__line:last-child {
  background: #fff;
  transform: rotate(45deg) translate(-3px, -4px);
}

.navbar__mobile {
  height: 100%;
}

/* .dropbtn .navbar__menu__item {
  line-height: 60px;
} */

.dropdown-links .navbar__menu__item__height {
  line-height: 50px;
}

.dropdown-links > svg {
  display: block;
  width: 16px;
  height: 16px;
  will-change: transform;
  color: #000 !important;
  transition: transform 0.3s ease;
}

.dropdown-links,
.dropdown-links-second {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
}

.rotate__icon {
  transform: rotate(180deg);
}

.dropbt {
  line-height: 50px;
}

@media (min-width: 1024px) {
  .dropbtn:hover > .dropdown-content {
    display: block;
  }

  .dropbtn:hover .dropdown-links > svg {
    transform: rotate(180deg);
  }

  .dropbtn .dropdown-links-second > svg {
    margin-right: 10px;
  }

  .dropbtn-second {
    position: relative;
  }

  .dropdown-content-second {
    top: 0;
    right: -325px;
  }

  .dropdown-content-second a {
    min-width: 325px;
  }
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #ffffff;
  min-width: 128px;
  box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.dropdown-content-second {
  box-shadow: 10px 5px 5px rgba(0, 0, 0, 0.1);
}

.dropdown-content a {
  display: block;
  font-size: 1.125rem;
  font-weight: 400;
  color: #000;
  padding: 12px 16px;
  transition: color 0.3s ease;
}

.dropdown-content a:hover {
  color: rgba(0, 0, 0, 0.75);
}

.main {
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
}

.main__background {
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  top: 0px;
  /* background-image: url('../imgs/background.jpg');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat; */
  z-index: 1;
  will-change: top;
  transition: top 0.45s cubic-bezier(0.77, 0, 0.175, 1);
}

.main__background.top {
  top: 0px;
}

.filter__blur {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: inherit;
  height: inherit;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(3px);
}

.main__container {
  width: 100%;
  max-width: 1440px;
  padding: 0 60px;
}

.main__cta {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  z-index: 1;
  flex-grow: 1;
}

.main__cta__h1 {
  display: block;
  font-size: 5rem;
  font-weight: bold;
  line-height: 6rem;
  color: #fff;
  font-style: italic;
  text-transform: uppercase;
  text-align: center;
  max-inline-size: 15ch;
  line-height: 120%;
  text-shadow: 5px 5px #f28937;
}

.main__buttons {
  display: flex;
  gap: 40px;
}

.main__cta_button.extra__style {
  color: #f28937;
  background-color: #ffffff;
  font-size: 1.625rem;
  padding: 25px 35px;
}

.main__cta_button.extra__style:hover {
  color: #f28937;
  border: 1px solid #f28937;
}

.main__cta_button,
.stacked__card__button,
.locations__button,
.fresh__button,
.job__apply__button,
.job__button,
.wizard__button,
.contact__button {
  display: block;
  width: fit-content;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
  background-color: #000;
  border-radius: 100px;
  padding: 19px 30px;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid transparent;
  transition: color 300ms ease, background-color 300ms ease, border 300ms ease;
}

.job__apply__button {
  margin: 0 auto;
}

.main__cta_button:hover,
.stacked__card__button:hover,
.locations__button:hover,
.fresh__button:hover,
.job__apply__button:hover,
.job__button:hover,
.wizard__button:hover,
.contact__button:hover {
  color: #000;
  background-color: transparent;
  border: 1px solid #000;
}

.main__searchbar__wrapper {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-shrink: 1;
  gap: 20px;
  background-color: #ff4632;
  border: 1px solid transparent;
  border-radius: 100px;
  padding: 10px 10px 10px 25px;
  margin-bottom: 60px;
}

.main__searchbar {
  position: relative;
  width: 100%;
}

.main__searchbar__input {
  width: 100%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 1.625rem;
  font-weight: 300;
  color: #000;
}

.main__searchbar__input::placeholder {
  color: #fff;
}

.main__searchbar__button {
  position: relative;
  flex-shrink: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #000;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjA0LjY0bC0uNjUyLjc1OCA4LjI2NiA3LjEwOEguMDAxdjFoMjAuNjU0bC04LjI2OCA3LjEzNC42NTQuNzU4IDkuNzI2LTguMzk0TDEzLjA0LjY0eiIgZmlsbD0iIzE5MTQxNCIvPjwvc3ZnPg==);
  background-position: center center;
  background-repeat: no-repeat;
  background-color: #fff;
  border: 1px solid transparent;
  cursor: pointer;
  transition: color 300ms ease, background-color 300ms ease, border 300ms ease;
}

.main__searchbar__button:hover {
  color: #fff;
  background-color: transparent;
  border: 1px solid #fff;
}

.container {
  position: relative;
  z-index: 1;
}

.stacked__cards {
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-bottom: calc(237px);
  background-color: #ffffff80;
  z-index: 2;
}

.stacked__card__link {
  display: flex;
  align-items: center;
  gap: 14px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  border-top: 1px solid rgba(16, 16, 24, 0.12);
  border-left: 1px solid rgba(16, 16, 24, 0.12);
  border-right: 1px solid rgba(16, 16, 24, 0.12);
  margin-left: -1px;
  margin-right: -1px;
  padding: 20px 0;
}

.stacked__card__no {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 38px;
  height: 38px;
  font-size: 1.625rem;
  font-weight: 300;
  line-height: 150%;
  text-align: center;
  color: #000;
  border-radius: 50%;
  border: 1px solid #000000;
  margin-left: 4.1666666667%;
}

.stacked__card__issue {
  font-size: 1.625rem;
  font-weight: 300;
  line-height: 150%;
  color: #000;
}

.stacked__card {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  margin-left: -20px;
  margin-right: -20px;
}

.stacked__card__left__col {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 20px;
  padding-left: 20px;
  flex-basis: 30%;
  max-width: 30%;
  margin-left: 4.1666666667%;
}

.stacked__card__right__col {
  box-sizing: border-box;
  flex: 0 0 auto;
  padding-right: 20px;
  padding-left: 20px;
  flex-basis: 50%;
  max-width: 50%;
  margin-left: 4.1666666667%;
}

.stacked__card__thumbnail {
  object-fit: cover;
  aspect-ratio: 1 / 1;
  border-radius: 20px;
}

.stacked__card__heading {
  line-height: 110%;
  font-size: 2.75rem;
  letter-spacing: -0.02em;
  margin-bottom: 20px;
  font-weight: 400;
}

.stacked__card__paragraph {
  line-height: 150%;
  font-size: 1.3rem;
  font-weight: 300;
  margin-top: 20px;
  margin-bottom: 40px;
}

.featured__jobs {
  position: relative;
  background-color: #ffffff80;
  padding: 125px 0;
  z-index: 2;
}

.featured__jobs .main__container:first-of-type {
  margin: 0 auto 70px auto;
}

.featured__jobs__heading {
  font-size: 3.625rem;
  text-align: center;
  font-weight: 400;
  letter-spacing: -0.02em;
  color: #000;
  line-height: 1.15;
}

.swiper-container {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: hidden;
  list-style: none;
  padding: 0;
  z-index: 1;
  padding: 0 60px;
  margin: 0 auto;
}

.fresh__content .swiper-container {
  padding: 0 calc((100% - 1440px) / 2 + 60px);
}

.fresh__content .swiper-slide {
  max-width: 413px;
}

.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}

.swiper-slide {
  position: relative;
  display: flex;
  flex-shrink: 0;
  width: 100%;
  height: auto;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}

.swiper-card {
  display: flex;
  width: 100%;
  max-width: 500px;
  min-height: 500px;
  flex-direction: column;
  justify-content: space-between;
  flex-shrink: 0;
  text-decoration: none;
  text-align: start;
  border: 1px solid transparent;
  transition: border 0.25s ease, border-color 0.4s ease,
    background-color 0.4s ease;
  padding: 30px;
  margin: 0 auto;
  border-radius: 20px;
}

.swiper-slide-active .swiper-card {
  border: 1px solid transparent !important;
  background-color: #ffd981;
  color: rgba(25, 20, 20, 0.3);
}

.swiper-card:not(.swiper-slide-active) {
  border: 1px solid rgba(16, 16, 24, 0.12);
}

.swiper-pagination {
  position: absolute;
  bottom: -10px !important;
  left: 50%;
  transform: translateX(-50%);
}

.swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #000;
  opacity: 0.5;
  margin: 0 8px;
  cursor: pointer;
}

.swiper-pagination-bullet-active {
  opacity: 1;
}

.slider__top {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.slider__job__tag {
  display: inline-flex;
  min-height: 40px;
  font-size: 1.125rem;
  font-weight: 400;
  color: #000;
  align-items: center;
  letter-spacing: 0.01em;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 100px;
  padding: 0 15px;
}

.slider.active .slider__job__tag {
  background-color: hsla(0, 0%, 100%, 0.3);
}

.slider__job__title,
.slider__job__position {
  line-height: 120%;
  font-weight: 400;
  font-size: 2.375rem;
  text-align: center;
  color: #000;
  letter-spacing: -0.02em;
  word-break: break-all;
  word-break: break-word;
}

.slider__job__position {
  color: rgba(56, 56, 56, 0.651);
}

.slider__job__location {
  font-size: 1.125rem;
  font-weight: 400;
  color: #000;
  letter-spacing: 0.01em;
  text-align: center;
}

.slider__control__wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}

.slider__control__position {
  display: none;
}

.slider__control__left,
.slider__control__right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: calc(50% - ((413px * 0.5) + 100px + 60px));

  width: 60px;
  height: 60px;
  border-radius: 50%;
  border: 1px solid #000;
  background-color: transparent;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjA0LjY0bC0uNjUyLjc1OCA4LjI2NiA3LjEwOEguMDAxdjFoMjAuNjU0bC04LjI2OCA3LjEzNC42NTQuNzU4IDkuNzI2LTguMzk0TDEzLjA0LjY0eiIgZmlsbD0iIzE5MTQxNCIvPjwvc3ZnPg==);
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transition: background-color 300ms ease, border 300ms ease;
  z-index: 1;
}

.slider__control__left {
  transform: scaleX(-1) translateY(-50%);
}

.slider__control__right {
  left: auto;
  right: calc(50% - ((413px * 0.5) + 100px + 60px));
}

.slider__control__left:hover,
.slider__control__right:hover {
  background-color: #000;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjA0LjY0bC0uNjUyLjc1OCA4LjI2NiA3LjEwOEguMDAxdjFoMjAuNjU0bC04LjI2OCA3LjEzNC42NTQuNzU4IDkuNzI2LTguMzk0TDEzLjA0LjY0eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
}

/* Clients */
.clients__slider__wrapper {
  position: relative;
  padding: 260px 0;
  background-color: #ffffff80;
  z-index: 2;
}

.clients__container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
}

.client__slider__card {
  position: relative;
  display: flex !important;
  width: 100%;
  max-width: 304px;
  aspect-ratio: 1 / 1;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  border-radius: 10px;
  overflow: hidden;
}

.client__slider__card img {
  width: 100%;
  object-fit: cover;
}

.react-slideshow-container {
  width: 100vw;
  height: 100vh;
}

.each-slide-effect > div {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  height: 100vh;
}

.slide-wrap {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
}

.slide-wrap > h1 {
  width: calc(100% - 40px);
  max-width: 1024px;
  /* line-height: 110%; */
  font-size: 2.7rem;
  letter-spacing: -0.02em;
  margin-bottom: 20px;
  /* font-weight: 400; */
  font-family: "Poppins", normal;
  text-align: center;
  line-height: 150%;
  color: #fff;

  /* position: absolute;
  top: 40%;
  right: 50%;
  transform: translate(50%, -50%); */
  font-weight: 700;
  color: #ffffff;
  text-shadow: 1px 1px 1px #1a1a1a, 1px 2px 1px #1a1a1a, 1px 3px 1px #1a1a1a,
    1px 4px 1px #1a1a1a, 1px 5px 1px #1a1a1a, 1px 6px 1px #1a1a1a,
    1px 7px 1px #1a1a1a, 1px 8px 1px #1a1a1a, 1px 9px 1px #1a1a1a,
    1px 10px 1px #1a1a1a, 1px 18px 6px rgba(16, 16, 16, 0.4),
    1px 22px 10px rgba(16, 16, 16, 0.2), 1px 25px 35px rgba(16, 16, 16, 0.2),
    1px 30px 60px rgba(16, 16, 16, 0.4);
}

.react-slideshow-container + ul.indicators {
  position: absolute;
  bottom: 75px;
  left: 50%;
  transform: translateX(-50%);
  gap: 10px;
}

.slide-indicator {
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border: 1px #fff solid;
  border-radius: 50%;
  margin: 0;
}

.slide-indicator.active {
  background: #fff;
}

.locations {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-bottom: 125px;
  background-color: #fff;
  overflow: hidden;
  z-index: 2;
}

.center__screen {
  display: flex;
  align-items: center;
  justify-content: center;
}

.relative__wrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-left: -20px;
  margin-right: -20px;
}

.relative__cta {
  display: flex;
  flex-direction: column;
  max-width: 50%;
  align-items: center;
  padding: 125px 20px 0 20px;
}

.locations__heading {
  font-size: 3.625rem;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.02em;
  margin-bottom: 20px;
}

.locations__paragraph {
  font-size: 1.625rem;
  line-height: 150%;
  font-weight: 300;
  color: #191414;
  margin-bottom: 40px;
  text-align: center;
}

.locations__img__left {
  position: absolute;
  top: 110px;
  left: 0;
}

.locations__img__right {
  position: absolute;
  bottom: -57px;
  right: 60px;
}

.marquee__wrapper {
  margin-top: 150px;
}

.marquee {
  position: relative;
  display: flex;
  width: 100%;
  margin-bottom: 80px;
  overflow: hidden;
}

.marquee:last-of-type {
  margin-bottom: 0;
}

.marquee__inner {
  display: flex;
  position: relative;
  gap: 100px;
  flex-shrink: 0;
  will-change: transform;
  padding: 20px 0;
  margin-right: 100px;
  transition: transform 300ms linear;
}

.marquee:nth-child(2n) .marquee__inner {
  flex-flow: row-reverse;
}

.marquee .marquee__inner {
  transform: translate3d(0, 0, 0);
  animation: marquee 50s linear infinite;
}

.marquee:hover .marquee__inner {
  animation-play-state: paused;
}

@keyframes marquee {
  0% {
    transform: translate3d(var(--move-initial), 0, 0);
  }

  100% {
    transform: translate3d(var(--move-final), 0, 0);
  }
}

.marquee__link {
  position: relative;
  display: flex;
  white-space: nowrap;
  align-items: center;
  gap: 40px;
  color: #191414;
}

.marquee__img {
  width: 120px;
  height: 120px;
  border-radius: 20px;
  flex-shrink: 0;
  will-change: transform;
  transition: transform 300ms ease;
}

.marquee__link:hover .marquee__img {
  transform: scale(1.2);
}

.marquee__bullet {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
}

.marquee__bullet::after {
  content: "";
  position: absolute;
  width: 50px;
  height: 50px;
  top: 50%;
  left: 20px;
  transform: translate(-20px, -50%);
  border-radius: 50%;
  background-color: #000;
}

.marquee__paragraph {
  display: flex;
  font-size: 6.25rem;
  font-weight: 400;
  line-height: 100%;
  cursor: pointer;
}

.marquee__link:hover .marquee__paragraph {
  -webkit-text-fill-color: #fff;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: #191414;
}

.fresh__content {
  position: relative;
  min-height: 100vh;
  padding-top: 125px;
  padding-bottom: 125px;
  background-color: #fff;
  z-index: 2;
}

.fresh__content .main__container:first-of-type {
  margin: 0 auto 70px auto;
}

.fresh__content .main__container:last-of-type {
  display: flex;
  justify-content: center;
  margin: 70px auto 0 auto;
}

.fresh__heading,
.quick__heading {
  font-size: 3.625rem;
  font-weight: 400;
  line-height: 110%;
  letter-spacing: -0.02em;
  text-align: center;
}

.manipulation__slider__wrapper {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 15vw;
  overflow: hidden;
}

.manipulation__slider__container {
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  gap: 40px;
}

.manipulation__slider {
  position: relative;
  display: flex !important;
  width: 100%;
  max-width: 413px;
  min-height: 500px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: #006450;
  border-radius: 20px;
  flex-shrink: 0;
  padding: 20px;
}

.manipulation__heading {
  font-size: 2.375rem;
  font-weight: 400;
  color: #fff;
  line-height: 120%;
  flex-grow: 1;
  word-wrap: break-word;
  margin-top: 25px;
  display: -webkit-box;
  -webkit-line-clamp: 7;
  -webkit-box-orient: vertical;
}

.manipulation__button {
  display: flex;
  align-self: flex-start;
  align-items: center;
  background: none;
  cursor: pointer;
  font-size: 1.375rem;
  font-weight: 400;
  color: #fff;
  line-height: 1.15;
}

.manipulation__button::after {
  content: "";
  display: inline-block;
  margin-left: 10px;
  width: 30px;
  height: 30px;
  -webkit-transition: all 0.25s ease;
  transition: all 0.25s ease;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 16px 12px;
  border-radius: 50%;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTguODYyLjExMmwtLjY1NC43NTdMMTQuMTU1IDZIMHYxaDE0LjE1M2wtNS45NDQgNS4xMTEuNjUyLjc1OSA3LjQwNi02LjM2OEw4Ljg2Mi4xMTJ6IiBmaWxsPSIjMDAwIi8+PC9zdmc+);
  background-color: #fff;
}

.manipulation__slider:hover .manipulation__button::after {
  background-color: transparent;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTguODYyLjExMmwtLjY1NC43NTdMMTQuMTU1IDZIMHYxaDE0LjE1M2wtNS45NDQgNS4xMTEuNjUyLjc1OSA3LjQwNi02LjM2OEw4Ljg2Mi4xMTJ6IiBmaWxsPSIjZmZmIi8+PC9zdmc+);
  border: 1px solid #fff;
}

.quick__clicks {
  position: relative;
  padding-top: 125px;
  padding-bottom: 125px;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  background-color: #fff;
  z-index: 2;
  overflow: hidden;
}

.quick__clicks .main__container {
  margin: 0 auto;
}

.quick__heading {
  margin-bottom: 70px;
}

.quick__links {
  border-radius: 100px;
  border: 1px solid rgba(16, 16, 24, 0.12);
  padding: 30px 30px 30px 40px;
  display: flex;
  justify-content: space-between;
  text-decoration: none;
  align-items: center;
  font-size: 2.375rem;
  font-weight: 300;
  line-height: 140%;
  color: rgba(16, 16, 24, 0.6);
  transition: color 0.45s;
  transition: border-color 0.25s ease;
  margin-bottom: 30px;
  gap: 20px;
}

.quick__links:last-of-type {
  margin-bottom: 0;
}

.quick__info__wrapper > * {
  display: inline;
}

.quick__links span {
  color: #191414;
  font-weight: 400;
}

.quick__button {
  width: 60px;
  height: 60px;
  flex-shrink: 0;
  border-radius: 50%;
  border: 1px solid #000;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjA0LjY0bC0uNjUyLjc1OCA4LjI2NiA3LjEwOEguMDAxdjFoMjAuNjU0bC04LjI2OCA3LjEzNC42NTQuNzU4IDkuNzI2LTguMzk0TDEzLjA0LjY0eiIgZmlsbD0iI2ZmZiIvPjwvc3ZnPg==);
  background-color: #000;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
  transform: translateX(0px);
  transition: background-color 300ms ease, border 300ms ease,
    transform 300ms ease;
}

.quick__links:hover .quick__button {
  transform: translateX(-12px);
  background-color: transparent;
  border: 1px solid #000;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjMiIGhlaWdodD0iMTgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTEzLjA0LjY0bC0uNjUyLjc1OCA4LjI2NiA3LjEwOEguMDAxdjFoMjAuNjU0bC04LjI2OCA3LjEzNC42NTQuNzU4IDkuNzI2LTguMzk0TDEzLjA0LjY0eiIgZmlsbD0iIzE5MTQxNCIvPjwvc3ZnPg==);
}

.footer {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: #000;
  padding: 40px 0 40px;
  pointer-events: auto;
}

.footer.fixed {
  position: fixed;
  bottom: 0;
}

.pre__footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer__icon {
  width: 120px;
  height: auto;
}

.pre__footer__box {
  display: flex;
  max-width: 640px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin-bottom: 40px;
}

.pre__footer__heading {
  font-size: 3.625rem;
  line-height: 110%;
  letter-spacing: -0.02em;
  color: #fff;
  font-weight: 400;
  text-align: center;
}

.pre__footer__paragraph {
  font-size: 1.625rem;
  line-height: 150%;
  font-weight: 300;
  text-align: center;
  color: #fff;
}

.pre__footer__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
}

.pre__footer__button {
  border-radius: 100px;
  padding: 19px 30px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  border: 1px solid #fff;
  font-size: 1.125rem;
  letter-spacing: 0.01em;
  transition: all 0.2s ease;
}

.pre__footer__button:hover {
  color: #000;
  background-color: #fff;
}

.footer__menu {
  position: relative;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 40px;
  margin-top: 150px;
  padding: 0 20px;
}

.footer__menu__header__wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  overflow: hidden;
}

.footer__menu__header {
  font-size: 1.125rem;
  line-height: 150%;
  color: #fff;
  letter-spacing: 0.01em;
}

.footer__menu__header__wrapper svg {
  display: none;
  height: 16px;
  width: 16px;
  color: #fff;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.footer__menu__header__wrapper svg.open {
  transform: rotate(-180deg);
}

.footer__menu__list__wrapper {
  display: block;
  max-height: 0px;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.3s ease, opacity 0.3s ease;
}

.footer__menu__list__wrapper.open {
  max-height: var(--height);
  opacity: 1;
  transition-timing-function: ease, ease;
}

.footer__menu__list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 1rem;
  letter-spacing: 0.01em;
  line-height: 1.15;
  gap: 20px;
}

.footer__menu__link {
  color: hsla(0, 0%, 100%, 0.6);
  transition: color 300ms ease;
}

.footer__menu__link:hover {
  color: #fff;
}

.footer__bottom {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-top: 70px; */
  gap: 80px;
  padding: 0 40px;
}

.footer__bottom__list {
  position: relative;
  display: flex;
  align-items: center;
  gap: 40px;
  margin: 0;
}

.footer__bottom___link {
  font-size: 0.875rem;
  letter-spacing: 0.01em;
  line-height: 1.15;
  color: hsla(0, 0%, 100%, 0.6);
  transition: color 300ms ease;
}

.footer__bottom___link:hover {
  color: #fff;
}

.footer__pusher {
  pointer-events: none;
}

#container {
  text-align: center;
}

#text {
  display: inline;
  vertical-align: middle;
  font-size: 5rem;
  color: #000;
  letter-spacing: -0.02em;
  line-height: 150%;
}

#cursor {
  display: inline-block;
  vertical-align: middle;
  width: 1px;
  height: 65px;
  background-color: #000;
}

.jobs__filter__wrapper {
  position: relative;
  display: flex;
  max-width: 1024px;
  align-items: center;
  justify-content: space-between;
  gap: 40px;
  margin: 0 auto;
}

.select__container {
  position: relative;
  max-width: 320px;
  width: 100%;
}

.select__btn {
  display: flex;
  justify-content: space-between;
  border-radius: 100px;
  border: 1px solid rgba(16, 16, 24, 0.12);
  padding: 13px 25px;
  align-items: center;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.select__btn:hover {
  border-color: #000;
}

.select__btn .btn__text {
  font-size: calc(1.125rem + 0.25 * ((100vw - 48rem) / 42));
  font-weight: 400;
  line-height: 150%;
  color: #000;
}

.select__btn svg {
  display: flex;
  height: 21px;
  width: 21px;
  color: #000;
  font-size: 14px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

.select__btn.open svg {
  transform: rotate(-180deg);
}

.list-items {
  position: absolute;
  display: none;
  background: #fff;
  border: 1px solid #191414;
  width: 100%;
  z-index: 1;
  max-height: 400px;
  padding: 20px;
  margin-top: 20px;
  border-radius: 20px;
  overflow-y: auto;
}

.select__btn.open ~ .list-items {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.list-items .item {
  display: flex;
  width: fit-content;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

[type="checkbox"]:checked,
[type="checkbox"]:not(:checked) {
  position: absolute;
  width: 0;
  height: 0;
  visibility: hidden;
}

[type="checkbox"]:checked + label,
[type="checkbox"]:not(:checked) + label {
  position: relative;
  display: inline-block;
  font-size: calc(0.875rem + 0.125 * ((100vw - 48rem) / 42));
  color: #000;
  line-height: 120%;
  padding-left: 30px;
  cursor: pointer;
}

[type="checkbox"]:checked + label:before,
[type="checkbox"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: -1px;
  height: 20px;
  width: 20px;
  background-color: #fff;
  border: 1px solid #191414;
  border-radius: 5px;
}

[type="checkbox"]:checked + label:before {
  border-color: #ffc864;
  background-color: #ffc864;
}

[type="checkbox"]:checked + label:after,
[type="checkbox"]:not(:checked) + label:after {
  content: "";
  position: absolute;
  display: none;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTAiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0xMS43MjcgMS4zNzlMMTAuNDA0LjMxNSA0LjEzNyA3LjIyMSAxLjQ3IDQuMjgxLjMyNSA1LjU0bDMuODEyIDQuMjAzIDcuNTktOC4zNjV6IiBmaWxsPSIjMTkxNDE0Ii8+PC9zdmc+)
    no-repeat;
  background-size: 12px 9px;
  width: 12px;
  height: 9px;
  left: 4px;
  top: 5px;
}

[type="checkbox"]:checked + label:after {
  display: block;
}

.jobs__subheading {
  color: rgba(16, 16, 24, 0.6);
  text-align: center;
  line-height: 150%;
  font-size: calc(1.375rem + 0.25 * ((100vw - 48rem) / 42));
}

.jobs__subheading span {
  color: #000;
}

.jobs__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  padding-bottom: 125px;
  padding-top: 260px;
  z-index: 2;
}

.jobs__wrapper .main__container:nth-of-type(1),
.jobs__wrapper .main__container:nth-of-type(3) {
  margin-bottom: 70px;
}

.jobs__wrapper .main__container:nth-of-type(2) {
  margin-bottom: 80px;
}

.lds__ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds__ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #000;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds__ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds__ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds__ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds__ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.jobs__list {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1024px;
  gap: 20px;
  padding-bottom: 80px;
}

.job__position {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  border: 1px solid #cecece;
  transition: border 300ms ease;
}

.job__position:hover {
  border: 1px solid #222222;
}

.job__office {
  font-size: 12px;
  font-weight: bold;
  color: #222222;
  line-height: 12px;
}

.job__name {
  font-size: calc(1.125rem + 0.25 * ((100vw - 48rem) / 42));
  color: #191414;
  line-height: 150%;
  transition: color 300ms ease;
}

.job__name:hover {
  color: #ffc864;
}

.job__type {
  display: block;
  width: fit-content;
  font-size: 14px;
  line-height: 14px;
  padding: 5px 10px;
  border-radius: 12px;
  background-color: #ffc864;
  color: black;
  border: 1px solid #ffc864;
  cursor: pointer;
  transition: background-color 300ms ease;
}

.job__type:hover {
  background-color: transparent !important;
}

.job__wrapper {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  z-index: 2;
}

.job__right__side,
.job__left__side {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 70px;
  background-color: #ffd981;
  padding: 70px;
}

.job__left__side {
  position: sticky;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  height: 100vh;
}

.job__right__side {
  background-color: #ffffff80;
  z-index: 1;
}

.scroll__down {
  position: absolute;
  width: 16px;
  height: 16px;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.job__card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 80px;
}

.job__title,
.job__subtitle {
  font-size: calc(1.125rem + 1.875 * ((100vw - 56rem) / 42));
  line-height: 100%;
  letter-spacing: -0.02em;
  word-break: break-word;
  text-align: center;
}

.job__title {
  margin-bottom: 30px;
}

.job__subtitle {
  font-size: calc(1.125rem + 0.25 * ((100vw - 48rem) / 42));
  color: rgba(25, 20, 20, 0.75);
  flex-grow: 1;
}

.job__paragraph {
  font-size: calc(1.125rem + 0.5 * ((100vw - 48rem) / 42)) !important;
  font-weight: 300;
  line-height: 150%;
  color: #191414;
}

.job__split__screen {
  position: relative;
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.job__split__column {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.job__split__column p:nth-of-type(1) {
  color: rgba(16, 16, 24, 0.38);
  line-height: 120%;
  font-size: calc(0.875rem + 0.125 * ((100vw - 48rem) / 42));
}

.job__split__column p:nth-of-type(2) {
  font-size: calc(1.125rem + 0.25 * ((100vw - 48rem) / 42));
  line-height: 150%;
  font-weight: 300;
}

.job__list__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  width: 100%;
}

.job__list__heading {
  display: block;
  width: 100%;
  font-size: calc(1.375rem + 0.25 * ((100vw - 48rem) / 42));
  line-height: 150%;
  margin-bottom: 20px;
  text-align: left;
}

.job__list__wrapper .jobs__list {
  max-width: 100%;
}

.job__list {
  display: flex;
  flex-direction: column;
  gap: 40px;
  font-size: calc(1.125rem + 0.25 * ((100vw - 48rem) / 42));
  font-weight: 300;
  color: #191414;
  line-height: 150%;
}

.job__description {
  font-size: calc(1.125rem + 0.25 * ((100vw - 48rem) / 42));
  font-weight: 300;
  color: #191414;
  line-height: 150%;
}

.job__list__item {
  position: relative;
  padding-left: 22px;
}

.job__list__item::before {
  content: "";
  position: absolute;
  display: inline-block;
  background-color: #ffd981;
  width: 8px;
  height: 8px;
  top: 12px;
  left: 0;
  border-radius: 50%;
}

.job__right__side .job__apply__button {
  display: none;
}

.job__list__benefit {
  display: flex;
  gap: 20px;
  align-items: center;
}

.job__list__benefit img {
  align-self: baseline;
  width: 60px;
  height: auto;
}

.job__list__benefit p {
  font-size: calc(1.125rem + 0.25 * ((100vw - 48rem) / 42));
  line-height: 150%;
  font-weight: 300;
}

.job__list__benefit p span:nth-of-type(1) {
  font-weight: 400;
}

/*------------------*/
/* Energie Services
/*------------------*/
canvas {
  position: sticky;
  top: 50%;
  transform: translateY(-50%);
  max-width: 100vw;
  max-height: 100vh;
  margin: auto;
  display: block;
}

.container__headings {
  height: calc(3770px - 100vh);
  top: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-direction: column;
}

.container__headings div.h1 {
  width: 75%;
  font-size: 4rem;
  font-weight: 600;
  text-align: center;
  color: #f28937;
  line-height: 1;
}

.h1__buttons {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
}

.orange {
  color: #f28937 !important;
  border-color: #f28937 !important;
}

.text-shadow {
  text-shadow: 0 0 2px #fff;
}

.text-shadow:hover {
  color: #f7ae76 !important;
}

/*------------------*/
/* #. Wizard Media
/*------------------*/
.wizard__wrapper {
  position: relative;
}

.wizard__question,
.wizard__review {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
  width: 100%;
  min-height: 100vh;
  padding: 40px;
}

.wizard__review {
  display: none;
  opacity: 0;
}

.question__progress {
  position: fixed;
  top: 0;
  width: 100%;
  height: 5px;
}

.question__progress::after {
  content: "";
  display: block;
  width: var(--progress);
  height: 100%;
  background-color: #000000;
  transition: width 300ms ease;
}

.job__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.job__header h3 {
  font-size: 1.625rem;
  line-height: 1;
}

.question__indicator {
  flex-shrink: 0;
  align-self: center;
  font-size: calc(1.125rem + 0.25 * ((100vw - 48rem) / 42));
  line-height: 100%;
}

.questions__list,
.review__list {
  position: relative;
  width: 100%;
  max-width: 768px;
}

.review__list {
  display: flex;
  flex-direction: column;
  gap: 80px;
}

.question__item {
  position: absolute;
  display: flex;
  width: 100%;
  flex-direction: column;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 20px;
  justify-content: center;
  opacity: 0;
  pointer-events: none;
}

.question__item:nth-of-type(1) {
  opacity: 1;
  pointer-events: all;
}

.review__item {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
}

.question__label,
.review__label {
  font-size: 2.375rem;
  font-weight: 300;
  line-height: 100%;
  color: #191414;
}

.question__input,
.review__input {
  display: block;
  margin: 0;
  padding: 0 0 0.15em;
  width: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  background-color: transparent;
  color: #191414;
  text-overflow: ellipsis;
  font-size: 2.375rem;
  outline: none;
  line-height: 1;
  border-radius: 0;
}

.question__input::placeholder,
.review__label::placeholder {
  color: rgba(0, 0, 0, 0.2);
}

.question__input::-webkit-outer-spin-button,
.question__input::-webkit-inner-spin-button,
.review__input::-webkit-outer-spin-button,
.review__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.question__input[type="number"],
.review__input[type="number"] {
  appearance: textfield;
}

.wizard__control__wrapper,
.contact__control__wrapper {
  position: relative;
  width: 100%;
  max-width: 768px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.wizard__button.disabled {
  cursor: not-allowed;
  background-color: #4e4e4e;
}

.wizard__button:hover.disabled {
  cursor: not-allowed;
  background-color: #4e4e4e;
  border: 1px solid transparent;
  color: #fff;
}

.review__heading {
  font-size: 6rem;
  line-height: 100%;
  text-align: center;
  margin-bottom: 70px;
}

.terms__wrapper,
.privacy__wrapper,
.contact__wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom-left-radius: 40px;
  border-bottom-right-radius: 40px;
  padding: 125px 0;
  z-index: 2;
}

.terms__container,
.privacy__container {
  position: relative;
  width: 100%;
  max-width: 1024px;
}

.terms__heading,
.terms__heading2,
.terms__heading3,
.dsgvo__heading,
.dsgvo__heading3 {
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.02em;
  color: #000;
  margin-bottom: 20px;
}

.terms__heading {
  font-size: 3.625rem;
}

.terms__heading2 {
  font-size: 2.5rem;
}

.terms__heading3 {
  font-size: 2.375rem;
}

.terms__paragraph {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.01em;
  line-height: 1.15;
  margin-bottom: 40px;
}

.terms__paragraph span {
  display: block;
}

.terms__list {
  counter-reset: item;
  list-style-type: disc !important;
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.01em;
  line-height: 1.15;
  margin-bottom: 40px;
  padding-inline-start: 40px;
}

.terms__list > li {
  position: relative;
  display: block;
}

.terms__list > li::before {
  position: absolute;
  left: -35px;
  content: counters(item, ".") ". ";
  counter-increment: item;
}

.terms__container > .terms__list > li::before {
  left: -45px;
  font-size: 2.375rem;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.02em;
}

.terms__list[style*="list-style-type: lower-alpha;"] li:before {
  content: counters(item, ".", lower-alpha) ". ";
  counter-increment: item;
}

.terms__list a:hover {
  text-decoration: underline;
}

.terms__ul {
  list-style-type: circle !important;
  padding-inline-start: 40px;
}

.contact__wrapper {
  min-height: 100vh;
  height: 100%;
}

.contact__container {
  position: relative;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-gap: 70px;
  padding: 0 40px;
  flex-grow: 1;
}

.contact__left__col,
.contact__right__col {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 40px;
}

.contact__right__col {
  height: min-content;
  background-color: #ffd981;
  border-radius: 40px;
  padding: 40px;
}

.contact__options {
  display: flex;
  flex-direction: column;
  gap: 40px;
}

.contact__option {
  display: grid;
  grid-template-columns: max-content 1fr;
  gap: 10px;
}

.contact__option svg,
.contact__social__media svg {
  display: block;
  width: 16px;
  height: 16px;
  padding: 10px;
  border-radius: 0.5rem;
  border: 1px solid #b6b6b6;
  will-change: border-color;
  transition: border-color 300ms ease;
}

.contact__social__media svg:hover {
  border-color: #3d3d3d;
}

.contact__option svg,
.contact__option div {
  align-self: center;
}

.contact__option__title {
  font-size: 1.125rem;
  line-height: 150%;
  color: #000;
  letter-spacing: 0.01em;
}

.contact__option__description,
.contact__option__link {
  font-size: 1rem;
  letter-spacing: 0.01em;
  line-height: 1.15;
  color: #4e4e4e;
}

.contact__option__link {
  color: #000;
}

.contact__option > *:nth-child(3) {
  grid-column-start: 2;
}

.contact__option__link:hover {
  text-decoration: underline;
}

.contact__social__media {
  position: relative;
  display: flex;
  gap: 10px;
}

.contact__social__media a {
  color: #000;
}

.contact__top__wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.contact__heading {
  line-height: 120%;
  font-weight: 400;
  font-size: 2.375rem;
  color: #000;
  letter-spacing: -0.02em;
}

.contact__description {
  font-size: 1.125rem;
  font-weight: 400;
  color: #3d3d3d;
  letter-spacing: 0.01em;
}

.contact__button {
  align-self: center;
}

.contact__ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
}

.contact__input,
.contact__textarea {
  display: block;
  margin: 0;
  padding: 0 0 0.15em;
  width: 100%;
  border-bottom: 2px solid rgba(0, 0, 0, 0.2);
  background-color: transparent;
  color: #191414;
  text-overflow: ellipsis;
  font-size: 1.125rem;
  outline: none;
  line-height: 100%;
  border-radius: 0px;
}

.faq__wrapper {
  position: relative;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  padding-bottom: calc(237px);
  background-color: #fff;
  z-index: 2;
}

.faq__heading {
  position: sticky;
  top: 60px;
  line-height: 110%;
  font-size: 3.625rem;
  letter-spacing: -0.02em;
  font-weight: 400;
  margin: 20px 0 20px 70px;
}

.card__container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
  padding: 0 70px;
  margin: 0 auto;
}

.card {
  position: sticky;
  top: 145px;
  width: 100%;
  height: 256px;
  border-radius: 0.5rem;
  border: 1px solid #decede;
  background-color: #fff;
  padding: 20px;
}

.card__question {
  line-height: 120%;
  font-weight: 400;
  font-size: 2.375rem;
  color: #000;
  letter-spacing: -0.02em;
  word-break: break-all;
  word-break: break-word;
  margin-bottom: 20px;
}

.card__answer {
  position: relative;
  height: calc(100% - 2.375rem - 20px);
  overflow-y: auto;
  font-size: calc(1.125rem + 0.25 * ((100vw - 48rem) / 42));
  line-height: 150%;
  font-weight: 300;
}

/*-------------------------------------------------------------------------*/
/* #. Media Queries
/*-------------------------------------------------------------------------*/
/* .modal {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  max-width: 512px;
  max-height: 768px;
  background-color: #ffffff;
  border: none;
  border-radius: 0.5rem;
  padding: 0 20px 20px 20px;
}

.modal::backdrop {
  background-color: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
}

.modal__header {
  position: sticky;
  display: flex;
  top: 0;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
  background-color: #fff;
}

.modal__title {
  font-size: 1.625em;
  line-height: 1;
  font-weight: 400;
  text-transform: none;
}

.modal__close {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 0;
  cursor: pointer;
  padding: 0;
}

.modal__close svg {
  width: 16px;
  height: 16px;
  fill: #010001;
} */

.dsgvo__heading {
  font-size: 2.625rem;
  word-wrap: break-word;
}

.dsgvo__heading3 {
  font-size: 1.625rem;
  word-wrap: break-word;
}

.dsgvo__paragraph {
  display: flex;
  flex-direction: column;
  gap: 10px;
  font-size: 1rem;
  font-weight: 300;
  letter-spacing: 0.01em;
  line-height: 1.15;
  margin-bottom: 40px;
}

.services__sticky {
  position: sticky;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 400px;
  background-color: #fff;
  z-index: 2;
}

.services__fullpage {
  position: relative;
  display: flex;
  width: 100%;
  height: 100vh;
  align-items: center;
  justify-content: center;
}

.services__center__container {
  display: flex;
  flex-direction: column;
  max-width: 68%;
  padding: 0 20px;
  gap: 20px;
}

.center__position {
  margin: 0 auto;
}

.offset__2 {
  margin-left: 8.3333333333%;
}

.pb__section {
  padding-bottom: 125px;
}

.mb__70 {
  margin-bottom: 70px;
}

.sevenfive {
  max-width: 75%;
}

.services__center__heading {
  font-size: calc(2.5rem + 3.75 * ((100vw - 48rem) / 42));
  letter-spacing: -0.02em;
  line-height: 100%;
}

.services__center__heading2 {
  font-size: calc(2.125rem + 2.875 * ((100vw - 48rem) / 42));
  letter-spacing: -0.02em;
  line-height: 100%;
}

.services__center__heading3 {
  font-size: calc(1.625rem + 2 * ((100vw - 48rem) / 42));
  letter-spacing: -0.02em;
  line-height: 110%;
}

.services__center__subheading {
  font-size: calc(1.625rem + 0.75 * ((100vw - 48rem) / 42));
  letter-spacing: -0.02em;
  line-height: 140%;
  font-weight: 300;
}

.services__center__paragraph {
  font-size: calc(1.375rem + 0.25 * ((100vw - 48rem) / 42));
  letter-spacing: -0.02em;
  line-height: 150%;
  font-weight: 300;
}

.align__center {
  text-align: center;
}

.services__card__grid {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 150px;
}

.services__card__img {
  max-width: 37.5%;
  padding: 0 20px;
  margin-left: 8.3333333333%;
}

.services__card__img img {
  width: 100%;
  max-width: 470px;
  max-height: 470px;
  height: 660px;
  border-radius: 20px;
  object-fit: cover;
  object-position: center;
  overflow: hidden;
}

.service__card__wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 20px;
  max-width: 37.5%;
  margin-left: 4.1666666667%;
  gap: 20px;
}

.service__card__wrapper h2 {
  font-size: calc(1.625rem + 2 * ((100vw - 48rem) / 42));
  line-height: 120%;
  letter-spacing: -0.02em;
}

.service__card__wrapper p {
  font-size: calc(1.375rem + 0.25 * ((100vw - 48rem) / 42));
  font-weight: 300;
  line-height: 150%;
  letter-spacing: -0.02em;
}

.section__style {
  position: relative;
  z-index: 2;
  background-color: #fff;
  border-radius: 30px 30px 0 0;
  -webkit-box-shadow: 5px 5px 70px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 5px 5px 70px 0 rgba(0, 0, 0, 0.1);
  padding-top: 200px;
}

.services__sticky .swiper-container {
  padding: 60px;
}

.services__sticky .swiper-slide {
  max-width: 413px !important;
  width: 100% !important;
  min-height: 550px;
  perspective: 1000px;
}

.flipcard__inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flipcard__front,
.flipcard__back {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.flipcard__back {
  transform: rotateY(180deg);
}

.flipcard__overflow {
  overflow-y: auto;
}

.swiper-slide.open .flipcard__inner {
  transform: rotateY(180deg);
}

.flipcard__slider {
  position: relative;
  display: flex !important;
  width: 100%;
  max-width: 413px;
  min-height: 550px;
  height: 550px;
  flex-direction: column;
  background-color: #ffd981;
  border-radius: 20px;
  flex-shrink: 0;
  padding: 20px;
  box-shadow: 3px 3px 40px 0 rgba(0, 0, 0, 0.1);
}

.flipcard__slider img {
  width: 100%;
  height: 200px;
  object-fit: cover;
  margin: 0 auto;
}

.flipcard__heading {
  font-size: calc(1.625rem + 0.75 * ((100vw - 48rem) / 100));
  letter-spacing: -0.02em;
  line-height: 120%;
  margin-top: 30px;
  margin-bottom: 15px;
  color: #191414;
}

.flipcard__paragraph {
  font-size: calc(1.125rem + 0.25 * ((100vw - 48rem) / 42));
  font-weight: 300;
  line-height: 150%;
  color: #191414;
}

.flipcard__toggle {
  background: #f3f3f4;
  border-radius: 9999px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 20px;
  bottom: 20px;
  border: 1px solid transparent;
  cursor: pointer;
  -webkit-transition: border-color 0.25s ease;
  transition: border-color 0.25s ease;
  -webkit-transition: background-color 0.25s ease;
  transition: background-color 0.25s ease;
}

.flipcard__toggle:hover {
  border: 1px solid #191414;
  background: #fff;
}

.card__icon {
  width: 24px;
  height: 24px;
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTMiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTcuNSA1Ljk5OVYwSDYuNDk5djUuOTk5SC41djFoNS45OTlWMTNINy41VjYuOTk5aDZ2LTFoLTZ6IiBmaWxsPSIjMTkxNDE0Ii8+PC9zdmc+)
    no-repeat;
  background-size: 24px 24px;
  display: inline-block;
  align-self: center;
  -webkit-transition: -webkit-transform 0.35s ease;
  transition: -webkit-transform 0.35s ease;
  transition: transform 0.35s ease;
  transition: transform 0.35s ease, -webkit-transform 0.35s ease;
}

.minus {
  background: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTI4IDE0LjY2Nkg0djIuNjY3aDI0di0yLjY2NnoiIGZpbGw9IiMxOTE0MTQiLz48L3N2Zz4=)
    no-repeat 50%;
}

.services__we__are {
  position: relative;
  background-color: #ececec;
  margin: 200px 60px;
  padding: 150px 0;
  border-radius: 20px;
}

.hubspot__button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 140;
}

/*-------------------------------------------------------------------------*/
/* #. Media Queries
/*-------------------------------------------------------------------------*/

@media screen and (max-width: 1024px) {
  .main__container {
    padding: 0 20px;
  }

  /*------------------*/
  /* #. Navigation
    /*------------------*/
  .navbar__mobile {
    position: fixed;
    width: 100%;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: #000;
    transform: translateX(100%);
    padding: 0 20px;
    opacity: 0;
    transition: transform 300ms ease, opacity 300ms ease;
  }

  .navbar__mobile.open {
    transform: translateX(0%);
    opacity: 1;
  }

  .navbar__trigger {
    display: block;
  }

  .navbar__main__menu {
    padding: 0 20px;
  }

  .navbar__main__menu .navbar__menu__list {
    position: absolute;
    flex-direction: column;
    width: calc(100% - 40px);
    top: 0;
    margin-top: 70px;
  }

  .navbar__menu__item {
    font-size: calc(1.375rem + 0.25 * ((100vw - 48rem) / 42));
  }

  .navbar__main__menu > .navbar__menu__item {
    display: none;
  }

  .navbar__mobile.open li .navbar__menu__item {
    color: #fff !important;
  }

  .navbar__menu__list > .navbar__menu__item {
    display: block;
    height: fit-content;
  }

  .navbar__submenu {
    padding: 0;
    justify-content: unset;
    border-top: 1px solid rgba(16, 16, 24, 0.12);
    border-bottom: 1px solid rgba(16, 16, 24, 0.12);
    overflow-x: auto;
    transform: translate3d(0, 0, 0);
  }

  .navbar__submenu::-webkit-scrollbar {
    display: none;
  }

  .navbar__submenu::before,
  .navbar__submenu::after {
    content: "";
    min-width: 20px;
  }

  .navbar__submenu .navbar__menu__list {
    white-space: nowrap;
  }

  .navbar__submenu .navbar__menu__item {
    font-size: 0.875rem;
  }

  .dropdown-links > svg,
  .dropdown-links-second > svg {
    display: block;
    color: #fff !important;
  }

  .dropdown-links,
  .dropdown-links-second {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .dropdown-content {
    position: relative;
    background-color: transparent;
  }

  .dropdown-content a {
    color: #ffffff;
    margin-top: 15px;
    padding: 5px 15px;
  }

  .dropbtn .navbar__menu__item {
    line-height: 1;
  }

  .dropdown-content {
    margin-top: 20px;
  }

  .dropdown-content-second a {
    font-size: 1rem;
    margin-left: 10px;
  }

  .dropdown-content-second a::before {
    content: "- ";
  }

  .dropdown-links-second > svg {
    margin-right: 10px;
  }

  /*-------------------------------------------------------------------------*/
  /* #. Main Background 1024px
    /*-------------------------------------------------------------------------*/

  .main__cta {
    gap: 30px;
  }

  .main__cta__h1 {
    font-size: 2rem;
  }

  .main__searchbar__wrapper {
    margin-bottom: 20px;
  }

  .main__searchbar__input {
    font-size: 1rem;
  }

  .main__searchbar__button {
    width: 40px;
    height: 40px;
    background-size: 45%;
  }

  .main__cta_button.extra__style {
    font-size: 1rem;
    padding: 19px 30px;
  }

  /*-------------------------------------------------------------------------*/
  /* #. Stacked Cards 1024px
    /*-------------------------------------------------------------------------*/

  .stacked__card {
    display: grid;
    grid-gap: 30px;
    padding: 0 0 40px 0;
  }

  .stacked__card__link {
    gap: 0.5em;
  }

  .stacked__card__no {
    width: 1.5em;
    height: 1.5em;
    font-size: 0.875rem;
  }

  .stacked__card__issue {
    font-size: 0.875rem;
    font-weight: 300;
  }

  .stacked__card__left__col {
    grid-row: 2;

    max-height: 400px;
    min-height: 188px;
    max-width: unset;
    padding: 0 15px;
    margin: 0;
  }

  .stacked__card__right__col {
    grid-row: 1;

    max-width: unset;
    margin: 0;
    padding: 0 15px;
  }

  .stacked__card__thumbnail {
    aspect-ratio: unset;
    height: 400px;
    width: 100%;
    object-fit: cover;
    border-radius: 15px;
  }

  .stacked__card__heading {
    font-size: 1.125rem;
    margin-bottom: 10px;
  }

  .stacked__card__paragraph {
    font-size: 0.9rem;
    font-weight: 300;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  /*-------------------------------------------------------------------------*/
  /* #. Featured Jobs 1024px
    /*-------------------------------------------------------------------------*/

  .featured__jobs {
    padding: 80px 0;
  }

  .featured__jobs .main__container:first-of-type {
    margin: 0 auto 40px auto;
  }

  .featured__jobs__heading {
    font-size: 1.625rem;
  }

  .swiper-container {
    gap: 20px;
    padding: 40px 20px !important;
    transform: translate3d(0, 0, 0);
  }

  .swiper-card {
    max-width: 276px;
    min-height: 350px;
  }

  .slider__top {
    gap: 25px;
  }

  .slider__job__tag {
    min-height: 25px;
    font-size: 0.75rem;
    padding: 0 10px;
  }

  .slider__job__title,
  .slider__job__position {
    font-size: 1.625rem;
  }

  .slider__job__location {
    font-size: 0.75rem;
  }

  .slider__control__wrapper {
    position: relative;
    display: flex;
    width: 100%;
    top: unset;
    left: unset;
    transform: none;
    align-items: center;
    justify-content: space-between;
  }

  .slider__control__left,
  .slider__control__right {
    position: relative;
    transform: none;
    left: unset;
    right: unset;
  }

  .slider__control__left {
    transform: scaleX(-1);
  }

  .slider__control__position {
    display: block;
    font-size: 0.875rem;
  }

  .slider__control__left,
  .slider__control__right {
    width: 40px;
    height: 40px;
    background-size: 45%;
  }

  .client__slider__card {
    max-width: 156px;
  }

  /*-------------------------------------------------------------------------*/
  /* #. Locations 1024px
    /*-------------------------------------------------------------------------*/

  .locations__img__left {
    top: 40px;
    left: 20px;
    max-width: 69px;
  }

  .locations__img__right {
    display: none;
  }

  .relative__cta {
    max-width: 100%;
  }

  .locations__heading {
    font-size: 1.625rem;
    margin-bottom: 15px;
  }

  .locations__paragraph {
    font-size: 1.125rem;
  }

  .marquee {
    margin-bottom: 10px;
  }

  .marquee__wrapper {
    margin-top: 70px;
  }

  .marquee__inner {
    gap: 30px;
  }

  .marquee__link {
    gap: 15px;
  }

  .marquee__img {
    width: 60px;
    height: 60px;
    border-radius: 10px;
  }

  .marquee__bullet::after {
    width: 30px;
    height: 30px;
    top: 50%;
    left: 52.5px;
    transform: translate(-50%, -50%);
  }

  .marquee__paragraph {
    font-size: 2.5rem;
  }

  /*-------------------------------------------------------------------------*/
  /* #. Fresh Content 1024px
    /*-------------------------------------------------------------------------*/

  .fresh__content {
    padding: 80px 0;
  }

  .fresh__content .swiper-wrapper:first-of-type {
    margin-bottom: 40px;
  }

  .fresh__content .swiper-container {
    padding: 0 20px;
  }

  .fresh__content .main__container:last-of-type {
    margin: 0;
  }

  .fresh__heading {
    font-size: 1.625rem;
  }

  .manipulation__slider__container {
    gap: 20px;
  }

  .manipulation__slider__wrapper {
    padding: 0 20px;
  }

  .manipulation__slider {
    max-width: 276px;
    min-height: 350px;
  }

  .manipulation__heading {
    font-size: 1.375rem;
  }

  .manipulation__button {
    font-size: 0.875rem;
  }

  .main__cta_button,
  .stacked__card__button,
  .locations__button,
  .fresh__button {
    font-size: 0.875rem;
  }

  /*-------------------------------------------------------------------------*/
  /* #. Quick Clicks 1024px
    /*-------------------------------------------------------------------------*/

  .quick__clicks {
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;
    padding: 80px 0;
  }

  .quick__heading {
    font-size: 1.625rem;
    margin-bottom: 40px;
  }

  .quick__links {
    font-size: 1.125rem;
    padding: 10px 10px 10px 20px;
  }

  .quick__info__wrapper p {
    display: none;
  }

  .quick__info__wrapper span {
    text-transform: capitalize;
  }

  .quick__button {
    width: 40px;
    height: 40px;
  }

  /*-------------------------------------------------------------------------*/
  /* #. Footer 1024px
    /*-------------------------------------------------------------------------*/

  .footer__icon {
    width: 80px;
  }

  .pre__footer__heading {
    font-size: 1.625rem;
  }

  .pre__footer__paragraph {
    font-size: 1.125rem;
  }

  .pre__footer__buttons {
    flex-wrap: wrap;
  }

  .pre__footer__button {
    font-size: 0.875rem;
  }

  .footer {
    padding: 50px 0 30px 0;
  }

  .footer__menu {
    grid-template-columns: 1fr;
    padding: 0;
    margin-top: 70px;
  }

  .footer__menu__header {
    font-size: 0.875rem;
  }

  .footer__menu__header__wrapper svg {
    display: block;
  }

  .footer__menu__link {
    font-size: 0.75rem;
  }

  .footer__bottom__list {
    justify-content: center;
    flex-wrap: wrap;
    gap: 10px 20px;
  }

  .footer__bottom {
    flex-direction: column;
    padding: 0;
    gap: 10px 20px;
    margin-top: 30px;
  }

  .footer__bottom___link {
    font-size: 0.75rem;
  }

  #text {
    font-size: 2.125rem;
  }

  .jobs__filter__wrapper {
    flex-direction: column;
    gap: 20px;
  }

  .jobs__list {
    padding: 0 20px 40px 20px;
  }

  .job__wrapper {
    grid-template-columns: 1fr;
  }

  .job__right__side,
  .job__left__side {
    padding: 40px 20px;
  }

  .job__left__side {
    position: relative;
    height: 75vh;
  }

  .job__card__bottom {
    display: none;
  }

  .job__card {
    width: 100%;
  }

  .job__title,
  .job__subtitle {
    font-size: 2.125rem;
  }

  .job__paragraph {
    font-size: 1.125rem !important;
  }

  .job__split__column p:nth-of-type(1) {
    font-size: 0.75rem;
  }

  .job__split__column p:nth-of-type(2) {
    font-size: 1rem;
  }

  .job__list__heading {
    font-size: 1.125rem;
  }

  .job__list {
    font-size: 1rem;
    gap: 20px;
  }

  .job__description {
    font-size: 1rem;
  }

  .job__list__benefit img {
    width: 45px;
  }

  .job__list__benefit p {
    font-size: 0.875rem;
  }

  .job__list__wrapper:last-of-type {
    display: none;
  }

  .job__right__side .job__apply__button {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 0.875rem;
    padding: 14px 30px;
  }

  .container__headings div.h1 {
    font-size: 2.125rem;
  }

  .wizard__question,
  .wizard__review {
    gap: 40px;
    padding: 20px;
  }

  .review__heading {
    font-size: 2.5rem;
    margin-bottom: 30px;
  }

  .review__list {
    gap: 40px;
  }

  .review__item {
    gap: 10px;
  }

  .question__label,
  .review__label,
  .question__input,
  .review__input {
    font-size: 1.125rem;
  }

  .terms__wrapper,
  .privacy__wrapper {
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    padding: 80px 20px;
  }

  .terms__heading {
    font-size: 1.625rem;
  }

  .terms__heading2 {
    font-size: 1.375rem;
  }

  .terms__container > .terms__list > li::before,
  .terms__heading3 {
    font-size: 1.125rem;
  }

  .terms__paragraph,
  .terms__list {
    font-size: 0.75rem;
  }

  .contact__container {
    grid-template-columns: 100%;
    padding: 0 20px;
  }

  .contact__option__title {
    font-size: 0.875rem;
  }

  .contact__option__description,
  .contact__option__link {
    font-size: 0.75rem;
  }

  .contact__heading {
    font-size: 1.625rem;
  }

  .contact__description {
    font-size: 0.75rem;
  }

  .contact__input,
  .contact__textarea {
    font-size: 0.75rem;
  }

  .contact__right__col {
    border-radius: 20px;
    padding: 20px;
  }

  .contact__control__wrapper {
    flex-direction: column;
    align-items: unset;
  }

  .card__container {
    padding: 0 20px;
  }

  .card {
    top: 110px;
  }

  .faq__heading {
    font-size: 1.625rem;
    margin: 20px 0 20px 20px;
  }

  .card__question {
    font-size: 1.375rem;
  }

  .card__answer {
    height: calc(100% - 1.375rem - 20px);
    font-size: 1.125rem;
  }

  .services__fullpage {
    padding: 0 20px;
  }

  .services__center__container {
    max-width: 100%;
    padding: 0;
  }

  .services__center__heading {
    font-size: 3rem;
  }

  .services__center__subheading {
    font-size: 1.125rem;
  }

  .services__card__grid {
    flex-direction: column-reverse;
    gap: 40px;
    margin-bottom: 70px;
  }

  .services__card__img {
    max-width: 100%;
    margin-left: 0;
    padding: 0;
  }

  .service__card__wrapper {
    margin-left: 0;
    padding: 0;
    max-width: 100%;
  }

  .services__card__img img {
    max-width: 470px;
    max-height: 470px;
    height: auto;
  }

  .service__card__wrapper h2 {
    font-size: 1.625rem;
  }

  .service__card__wrapper p {
    font-size: 1.125rem;
  }

  .section__style {
    padding-top: 100px;
  }

  .pb__section {
    padding-bottom: 80px;
  }

  .services__center__heading2 {
    font-size: 2.125rem;
  }

  .services__center__heading3 {
    font-size: 1.625rem;
  }

  .offset__2 {
    margin-left: 0;
  }

  .services__sticky .swiper-slide {
    max-width: 276px !important;
    min-height: 350px;
    height: 350px;
  }

  .flipcard__slider {
    max-width: 276px;
    min-height: 350px;
    height: 350px;
  }

  .flipcard__slider img {
    height: 140px;
  }

  .flipcard__heading {
    font-size: calc(1.225rem + 0.25 * ((100vw - 48rem) / 42));
    margin-top: 25px;
  }

  .flipcard__paragraph {
    font-size: 0.875rem;
  }

  .services__we__are {
    padding: 80px 0;
    margin: 80px 20px;
  }

  .services__we__are .services__card__grid {
    flex-direction: column;
  }

  .services__we__are .services__card__img {
    max-width: 50%;
  }

  .services__we__are .services__card__img img {
    max-width: 288px;
    max-height: unset;
    height: auto;
  }

  /*-------------------------------------------------------------------------*/
  /* #. Feature Card 1024px
    /*-------------------------------------------------------------------------*/
  .feature-card-main-title {
    font-size: 3rem;
  }
}

@media screen and (max-width: 768px) {
  .cookie__wrapper {
    flex-direction: column;
  }

  .clients__slider__wrapper {
    padding: 0;
  }
}

@media screen and (max-width: 411px) {
}

.feature-card-main-title {
  line-height: 110%;
  font-size: 1.5rem;
  text-align: center;
  padding-bottom: 20px;
}

.feature-card-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px;
  padding: 20px;
  margin: auto;
  max-width: 1200px;
}

.feature-card {
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.feature-card-title {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}

.feature-card-description {
  font-size: 14px;
  color: #666;
  line-height: 1.6;
}

.feature-card-icon {
  margin-right: 10px;
  color: #333;
  justify-content: center;
}

.background__wrapper {
  position: relative;
  z-index: 2;
}

/* .background__wrapper *:not(.swiper-slide-active) {
  color: #000!important;
  background-color: #ffffff80!important;
} */

.background__image {
  position: sticky;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-image: url('../imgs/background_3_0.png');
  background-size: cover;
  background-position: center center;
}

.padding__wrapper {
  width: 100%;
  max-width: 1320px;
  margin: 0 auto;
  backdrop-filter: blur(20px);
  border-radius: 20px;
  overflow: hidden;
  background-color: #ffffff80;
  margin-top: calc(-100vh + 60px);
  margin-bottom: 60px;
}

/* .padding__wrapper > section {
  margin-top: -100vh;
} */

@media only screen and (max-width: 1490px) and (min-width: 1024px) {
  .img__logo__container {
    width: fit-content;
  }
}

@media only screen and (max-width: 768px) {
  .slide-wrap > h1 {
    top: 27%;
    font-size: 2rem;
    line-height: 110%;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1400px) {
  .slide-wrap > h1 {
    top: 40%;
    font-size: 2.1rem;
    line-height: 110%;
  }
}

 /* @media only screen and (min-width: 2560px) {
  .dropbtn .navbar__menu__item {
    line-height: 60px;
  }

   .dropdown-content-second {
    top: 20px;
  } 
}  */
