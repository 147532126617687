.container__wheel__component {
  z-index: 1;
}

.container__wheel__component .wheel {
  transform:translateY(0)
}

.container__wheel__component  p {
  padding: 10px;
  position: absolute;
  padding-top: 50px;
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  width: 100%;
}


@media screen and (min-width: 768px) {
  .container__wheel__component p {
    padding-top: 50px;
    font-size: 30px;
    line-height: 35px;
  }
}
