/* mobile first */

.entry__container {
  margin: 10px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  /* border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; */
  cursor: pointer;
  transition: all ease-in-out 0.4s;
  color: black;
  transition: all ease-in-out 0.4s;
  margin-bottom: 25px;
}

.entry__container:hover {
  transform: scale(1.03);
}

.entry__container .entry__image {
  width: 100%;
  height: 275px;
  object-fit: cover;
}

.entry__blur__image {
  filter: blur(2px);
}

.entry__container .entry__h3 {
  padding-top: 20px;
  min-height: 75px;
  text-align: center;
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 600;
}

.entry__container .entry__p {
  min-height: 50px;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
}

.entry__container .entry_button {
  width: 100%;
  background-color: black;
  color: white;
  font-size: 1rem;
  padding: 20px;
  /* border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; */
  cursor: pointer;
  border: 1px solid black;
}

.entry__container .entry_button_preview {
  width: 100%;
  background-color: grey;
  color: white;
  font-size: 1rem;
  padding: 20px;
  /* border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px; */
  cursor: pointer;
}

.entry__container .entry_button:hover {
  background-color: white;
  color: black;
}

@media screen and (min-width: 2560px) {
  .entry__container {
    margin: 0 225px;
    margin-bottom: 25px;
  }
  .entry__container .entry__image {
    width: 100%;
    height: 375px;
    object-fit: cover;
  }
}
