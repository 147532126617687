.gluecksrad__main {
  width: 100%;
  min-height: 100vh;
  background-color: "white";
  background-image: url("../imgs/background_gluecksrad.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.background__reload {
  width: 100%;
  min-height: 100vh;
}
.background__reload::before {
  content: "";
  background-image: url("../imgs/background_gluecksrad_reload.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  opacity: 0.40;
}

/* h1 {
  text-align: center;
  padding-top: 50px;
  font-size: 45px;
  color: #f79646;
  text-shadow: 0 0 1px black, 0 0 1px black, 0 0 1px black, 0 0 1px black;
  font-style: italic;
  font-weight: 700;
  text-transform: uppercase;
} */

.gluecksrad__container {
  padding-top: 125px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: 12.5px; */
  letter-spacing: 1px;
  color: #f79646;
  /* text-shadow: 5px 5px black;   */
}

.gluecksrad {
  position: relative;
  z-index: 99;
}

.gluecksrad__p {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  width: 100%;
  padding-bottom: 10px;
}

.gluecksrad__p__blue {
  color: #072c50;
}

/* .trapezoid {
  position: absolute;
  z-index: -1;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, 0);
  border-bottom: 100px solid #555;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  height: 0;
  width: 250px;
  filter: drop-shadow(0 0 40px #222);
} */

@media screen and (min-width: 576px) {
  /* .trapezoid {
    border-bottom: 150px solid #555;
    border-left: 30px solid transparent;
    border-right: 30px solid transparent;
    height: 0;
    width: 450px;
  } */
}

@media screen and (min-width: 768px) {
  .gluecksrad__container {
    padding-top: 200px;
  }
  .gluecksrad__p {
    font-size: 30px;
    line-height: 35px;
  }
}
