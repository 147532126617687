/* mobile first */

.heatfeed__container {
  position: relative;
  top: 60px;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-bottom: 350px;
  background-color: #fff;
  overflow: hidden;
  z-index: 2;
}

.heatfeed__header {
  width: 100%;
  min-height: 350px;
  background-image: url("../imgs/blog/title.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.heatfeed__section__text,
.heatfeed__section__entrys {
  padding: 20px;
}

.heatfeed__section__text {
  /* align-self: center; */
  letter-spacing: -0.02em;
}

.heatfeed__section__text h2 {
  text-align: center;
  font-size: 1.625rem;
  margin: 80px 0;
  line-height: 110%;
}

.heatfeed__section__text h5 {
  text-align: center;
  font-size: 1.325rem;
  margin: 40px 0;
  line-height: 100%;
  font-weight: bold;
}

.heatfeed__section__text .heatfeed__p {
  text-align: justify;
  /* padding: 0px 200px 50px; */
  padding: 0px 10px 50px;
  line-height: 150%;
  font-size: 0.9rem;
}

.heatfeed__section__text .heatfeed__ul {
  text-align: justify;
  /* padding: 0px 200px 50px; */
  padding: 0px 20px 50px;
  margin-bottom: 20px;
  line-height: 150%;
  font-size: 0.9rem;

  list-style-type: circle;
}

.heatfeed__section__text .center__p {
  text-align: center;
}

.heatfeed__section__text .padding__top__p {
  padding-top: 35px;
}

.heatfeed__academy__section__text {
  letter-spacing: -0.02em;
}

.heatfeed__academy__section__text .heatfeed__academy__p {
  line-height: 150%;
  font-size: 0.9rem;
}

.heatfeed__academy__section__text .heatfeed__ul li {
  padding: 10px 0;
  list-style-type: circle;
}

.heatfeed__card__div {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.heatfeed__container .blog__img {
  width: 100%;
  max-height: 450px;
  object-fit: cover;
}

.heatfeed__button__bar {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.heatfeed__product__button {
  display: flex;
  text-align: center;
  margin: 10px;
}

.heatfeed__back__button {
  position: absolute;
  margin-top: 100px;
  left: 50%;
  /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, -50%);
}

.button_pulse {
  animation: pulse 1.7s infinite 0.8s cubic-bezier(0.50, 0, 0, 1);
  /* box-shadow: 0 0 0 0 #072c50; */
  box-shadow: 0 0 0 0 #F79646;
}

.button_pulse:hover {
  animation: none;
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 18px rgba(255, 255, 255, 0);
  }
}

@media screen and (min-width: 768px) {
  .blog__container {
    padding-bottom: 125px;
  }

  .heatfeed__header {
    min-height: 450px;
  }

  .heatfeed__card__div {
    justify-content: center;
  }

  .heatfeed__section__text .heatfeed__p {
    /* width: 600px; */
    font-size: 1.3rem;
    font-weight: 300;
    margin-top: 20px;
  }

  .heatfeed__section__text .heatfeed__ul {
    /* width: 600px; */
    font-size: 1.3rem;
    font-weight: 300;
    margin-top: 20px;
    margin-bottom: 20px;
    list-style-type: circle;
  }

  .marginb__p {
    margin-bottom: 40px;
  }

  .blog__section__text {
    align-self: center;
    padding: 50px 175px;
  }
}

@media screen and (min-width: 1024px) {
  .heatfeed__section__text .heatfeed__p,
  .heatfeed__section__text .heatfeed__ul {
    padding: 0px 200px 50px;
  }

  .heatfeed__section__text h2,
  .heatfeed__academy__section__text h2 {
    font-size: 3.625rem;
  }
}
